// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import Location from '../_modules/location/location'

$(() => {
    new Location();

    var $api = '/webapi';
    // var $api = 'http://10.18.0.154:3000/webapi';

    if($('.product').length > 0){
        productCSSetup();
        productNASetup();

        function productCSSetup(){
            var $URL = $api + '/ProductApi/GetCroppingSegmentsFromCsv'

            $.ajax({
                url:$URL,
                dataType:'json'
            }).done(function(data){
                var $CroppingSegment =[];

                $.each(data,function(key,val){
                    $CroppingSegment.push('<option value="'+ val.CroppingSegmentValue +'">' + val.CroppingSegmentValue + '</option>')
                })
                $('#Cropping').append($CroppingSegment);
            });
        }

        function productNASetup(){
            var $URL = $api + '/ProductApi/GetNutrientsFromCsv'

            $.ajax({
                url:$URL,
                dataType:'json'
            }).done(function(data){
                var $Nutrient =[];

                $.each(data,function(key,val){
                    $Nutrient.push('<option value="'+ val.NutrientValue +'">' + val.NutrientValue + '</option>')
                })
                $('#Nutrient').append($Nutrient);
            });
        }
		$(document).on('keydown', function(e) {
			if (e.which == 13) {
				$('#productSearchBTN').click();
				e.preventDefault();
			}
		});
        $('#productSearchBTN').on('click',function(e){
            var $productName = $('#Product').val(),
                $nutrient = $('#Nutrient').val(),
                $croppingSegment = $('#Cropping').val(),
                $productId = $('#ProductID').val();

            var $URL = $api + '/ProductApi/FindProductsFromCsv?productName=' + $productName + '&nutrient=' +  $nutrient + '&croppingSegment=' + $croppingSegment + '&productId=' + $productId;

            getProduct($URL);
            e.preventDefault();
            return false;
        })

        $('#productResetBTN').on('click',function(e){
          //clearing fields value
          $('#Product').val("");
          $('#ProductID').val("");
          $("#Cropping option:selected").prop("selected", false)
          $("#Nutrient option:selected").prop("selected", false)
          $('fieldset.product > table > tbody > tr').remove();

          //showing all results
            // getProduct($api + '/ProductApi/FindProductsFromCsv');
            e.preventDefault();
            return false;
        })

        $('.product table').delegate('td','click',function(){
            var $open = $(this).hasClass("is-open");

            if($open){
                $(this).removeClass("is-open");
            } else {
                $(this).addClass("is-open");
            }
        })

        function getProduct($URL){
            $.ajax({
                url:$URL,
                dataType:'json'
            }).done(function(data){
                var $productReturned =[];

                $.each(data,function(key,val){
                    $productReturned.push('<tr><td><a href="javascript:void(0)" class="icon-down-arrow"><span>' + val.ProductName + '</span></a><div class="product-details"><p class="direction"> <span class="bold">Product Id:</span> '+ val.ProductId +'</p><a class="tag" href="'+ val.BagTagUrl +'" target="_blank">Bag tag</a><a class="tag" href="'+ val.CodeCDS +'" target="_blank">SDS</a><p class="direction">'+ val.NutrientAnalysis +'</p><p class="direction">'+ val.Nutrientstr +'</p></div></td><td><span>'+ val.CroppingSegmentStr +'</span></td></tr>')
                })
				if($productReturned == ""){
					$('.product table tbody').html('<p>No results found</p>');
				} else {
					$('.product table tbody').html($productReturned.join(''));
				}

            });

        };
    };

    if($('.profiles').length > 0){

        var $URL = $api + '/PeopleApi/FindPeopleFirstNameLettersFromCsv';

        alpha($URL)

        $('#contactChoice1').on('click',function(){
            var $URL = $api + '/PeopleApi/GetFirstNameLetters';

            alpha($URL)
        });

		teamSetup();

		function teamSetup(){
			var $URL1 = $api + '/PeopleApi/GetContactTypes'
            var $URL2 = $api + '/PeopleApi/GetStates'

			$.ajax({
                url:$URL1,
                dataType:'json'
            }).done(function(data){
                var $contactType =[];

                $.each(data,function(key,val){
                    $contactType.push('<option value="'+ data[key] +'">' + data[key] + '</option>')
                })
                $('#ContactType').append($contactType);
            });

			$.ajax({
                url:$URL2,
                dataType:'json'
            }).done(function(data){
                var $states =[];

                $.each(data,function(key,val){
                    $states.push('<option value="'+ val.Value +'">' + val.Display + '</option>')
                })
                $('#States').append($states);
            });
		}

        function alpha($URL){
            $.ajax({
                url:$URL,
                dataType:'json'
            }).done(function(data){
                var $letters =[];
                $.each(data,function(key,val){
					if(val.Exists == true){
						$letters.push('<li><a href="'+ val.Letter +'">'+ val.Letter +'</a></li>');
					} else {
						$letters.push('<li>'+ val.Letter +'</li>');
					}
                })
                $('.filters ul.letters').html($letters);
            });
        }

        $('#contactChoice2').on('click',function(){
            var $URL = $api + '/PeopleApi/GetLastNameLetters';
            alpha($URL)
        })

        $('#contactSearchBTN').on('click',function(e){
            var $ContactType = $('#ContactType').val(),
            $State = $('#States').val();

            var $URL = $api + '/PeopleApi/FindPeopleFromCsv?state='+ $State +'&contactType='+ $ContactType // real

            getProfile($URL);

            e.preventDefault();
            return false;
        });

        $('.filters ul.letters').delegate('a','click',function(e){
			var $letter = $(this).attr('href');

            if($('#contactChoice1').is(":checked")){
                var $URL = $api + '/PeopleApi/FindPeopleFromCsv?firstName=' + $letter
			} else {
                var $URL = $api + '/PeopleApi/FindPeopleFromCsv?lastName='+ $letter
            }

            getProfile($URL);
            e.preventDefault();
            return false;
        });

        $('#postcodeSearchBTN').on('click',function(e){
            var $Postcode = $('#Postcode').val();
            var $URL = $api + '/PeopleApi/FindPeopleFromCsv?postCode=' + $Postcode

            getProfile($URL)
            e.preventDefault();
            return false;
        });

        $('#mpostcodeSearchBTN').on('click',function(e){
            var $Postcode = $('#mPostcode').val();
            var $URL = $api + '/PeopleApi/FindPeopleFromCsv?postCode=' + $Postcode

            getProfile($URL)
            e.preventDefault();
            return false;
        });

        function getProfile($URL){
            $.ajax({
                url:$URL,
                dataType:'json'
            }).done(function(data){
                var $profileList =[];

                $.each(data,function(key,val){

                    var $profileImg = (val.ProfileImagePath == "") ? "/resources/ipf/imgs/profile.jpg" : val.ProfileImagePath,
                        $ProfileAlt = (val.ProfileImageName == "") ? "Profile image" : val.ProfileImageName,
                        $Country = val.Country == undefined ? "Australia" : val.Country;


                    $profileList.push('<div class="row underline"><div class="small-3 columns profile-img"><img src="' + $profileImg + '" alt="' + $ProfileAlt + '"></div><dl class="small-9 columns details"><div class="name">' + val.FirstName + ' ' + val.LastName + '</div>');

					if(val.Company!=""){$profileList.push('<dt><span class="bold">Company: </span></dt><dd><span>' + val.Company + '</span></dd>')};

					if(val.Region!=""){$profileList.push('<dt><span class="bold">Positiont: </span></dt><dd><span>'+ val.Region +'</span></dd>')};

          if(val.ContactType!=""){$profileList.push('<dt><span class="bold">Department: </span></dt><dd><span>'+ val.ContactType +'</span></dd>')};

					//if(val.MarketSegmentStr!=""){$profileList.push('<dt><span class="bold">Segment: </span></dt><dd><span>' + val.MarketSegmentStr + '</span></dd>')};

					if(val.PostalAddress!=""){$profileList.push('<dt><span class="bold">Address: </span></dt><dd><span>' + val.PostalAddress + '</span></dd>')};

					if($Country!=""){$profileList.push('<dt><span class="bold">Country: </span></dt><dd><span>' + $Country + '</span></dd>')};

					//if(val.NAUserName!=""){$profileList.push('<dt><span class="bold">NA Username: </span></dt><dd><span>' + val.NAUserName + '</span></dd>')}

					//if(val.AgroecologicalStr!=""){$profileList.push('<dt><span class="bold">Zone: </span></dt><dd><span>' + val.AgroecologicalStr + '</span></dd>')};

					//if(val.AreaOfSpecialisation!=""){$profileList.push('<dt><span class="bold">Specialisation: </span></dt><dd><span>' + val.AreaOfSpecialisation + '</span></dd>')};

					if(val.NAAAccreditation!=""){$profileList.push('<dt><span class="bold">NAA: </span></dt><dd><span>'+ val.NAAAccreditation +'</span></dd>')};

					if(val.FertcareAccreditedAdvisor!=""){$profileList.push('<dt><span class="bold">FA: </span></dt><dd><span>'+ val.FertcareAccreditedAdvisor +'</span></dd>')};

					if(val.Mobile!=""){$profileList.push('<dt><span class="bold">Mobile: </span></dt><dd><a href="' + val.Mobile + '">' + val.Mobile + '</a></dd>')};

					if(val.Email!=""){$profileList.push('<dt><span class="bold">Email: </span></dt><dd><a href="mailto:' + val.Email + '">' + val.Email + '</a></dd></dl></div>')};

					$profileList.push('</dl></div>');
                })


				if($profileList == ""){
					$('.profiles').html('<p>No profiles found</p>');
				} else {
					$('.profiles').html($profileList.join(''));
				}
            });

        };

    }
})
