'use strict';

export default class Location {
	constructor() {

		// You are a mobile if either height or width is under 600px
		var $isMobile = Math.min($(window).width(), $(window).height()) < 600;
		var dataStart; // specifies begining point data return from database
		var nextStart = 14;
		var dataCount = 15; // specifies number of data return from database
		var endLoadMore = false;

		if ($('.location__map').length > 0) {
			var callList = false;
			var markersSupplier = [];
			var markersDealer = [];
			var markersDealersupplier = [];
			var markersLocation = [];
			var markerClustersSupplier;
			var markerClustersDealer;
			var markerClustersDealersupplier;
			var markerClustersLocation;
			var mapSupplier = new google.maps.Map(document.getElementById('map-supplier'), {
				zoom: 3,
				center: {lat: -28.024, lng: 140.887}
			});
			var mapDealer = new google.maps.Map(document.getElementById('map-dealer'), {
				zoom: 3,
				center: {lat: -28.024, lng: 140.887}
			});
			var mapDealersupplier = new google.maps.Map(document.getElementById('map-dealersupplier'), {
				zoom: 3,
				center: {lat: -28.024, lng: 140.887}
			});
			var mapLocation = new google.maps.Map(document.getElementById('map-location'), {
				zoom: 3,
				center: {lat: -28.024, lng: 140.887}
			});

			populateStates();
			addFormListeners();
		}

		function addFormListeners() {
			$('.location__select').on('change', function() {
				$('.location__navigator-container').css('opacity','1');
				showType($(this).val());
				resetFilter($(this).val());
				initMap($(this).val());
			})

			$('#supplier-submit').on('click', function(e) {
				e.preventDefault();
		    $('div.message').remove();
				initMap('supplier', true, 0, dataCount);
			});

			$('#supplier-reset').on('click', function(e) {
				e.preventDefault();
				resetFilter('supplier');
		    $('div.message').remove();
				initMap('supplier');
			});

			$('#dealer-submit').on('click', function(e) {
				e.preventDefault();
		    $('div.message').remove();
				initMap('dealer', true, 0, dataCount);
			});

			$('#dealer-reset').on('click', function(e) {
				e.preventDefault();
				resetFilter('dealer');
		    $('div.message').remove();
				initMap('dealer');
			});

			$('#dealersupplier-submit').on('click', function(e) {
				e.preventDefault();
		    $('div.message').remove();
				initMap('dealersupplier', true, 0, dataCount);
			});

			$('#dealersupplier-reset').on('click', function(e) {
				e.preventDefault();
				resetFilter('dealersupplier');
		    $('div.message').remove();
				initMap('dealersupplier');
			});

			$('#location-submit').on('click', function(e) {
				e.preventDefault();
		    $('div.message').remove();
				initMap('location', true);
			});

			$('#location-reset').on('click', function(e) {
				e.preventDefault();
				resetFilter('location');
		    $('div.message').remove();
				initMap('location');
			});

			$('.location__more').on('click', function(e) {
				e.preventDefault();
				var type = $(this).parents('.location__navigator').data('type');
				loadMore(type, $(this));
			});

			$(document).on('keydown', function(e){

				var $value = $('.location__select :selected').val();

				if(e.keyCode === 13){

					if($value == 'location'){
						$('#location-submit').click();
					} else if($value =='dealersupplier'){
						$('#dealersupplier-submit').click();
					}
					e.preventDefault();
				}

			});
		}

		function populateStates(){
			$.ajax({
        url: `${getEnvironment()}/webapi/PeopleApi/GetStates`,
				// url: 'api/states.json',
                dataType: 'json'
            }).done(function(data){
                var $states = [];

                $.each(data,function(key, val){
                    $states.push('<option value="'+ val.Value +'">' + val.Display + '</option>')
                })
                $('#supplier-state').append($states);
				$('#dealer-state').append($states);
				$('#dealersupplier-state').append($states);
            });
		}

		function getEnvironment() {
			if (window.location.hostname === 'localhost') {
				return 'http://10.18.1.193:3002'; // BE's IP
			} else {
				return window.location.origin;
			}
		}

		function resetFilter(type) {
			$(`#${type}-town`).val('');
			$(`#${type}-group`).val('');
			$(`#${type}-partner`).val('');
			$(`#${type}-postcode`).val('');
			$(`#${type}-bignagent`).val('');
			$(`#${type}-state`).prop('selectedIndex', 0);
			$(`#${type}-locationtype`).prop('selectedIndex', 0);
			$('.location__list-item').remove();
		}

		function showType(type) {
			$('.location__filters').hide();
			$(`.location__filters[data-type="${type}"]`).show();
			$('.location__navigator').css({'opacity':'0','visibility':'hidden','height':'0','overflow':'hidden'});
			$(`.location__navigator[data-type="${type}"]`).css({'opacity':'1','visibility':'visible','height':'auto','overflow':'auto'});
		}

		function listReset($targetList) {
			$targetList.empty();
		}

		// Populate the location list for desktop
		function populateList($targetList, type, data) {
			var html = '';
			var count = 0;
			var $moreContainer = $(`.location__navigator[data-type="${type}"] .location__more-container`);

			$moreContainer.hide();

			$.each(data, function(index, value) {
				count++;
				html += `
					<li class="location__list-item" data-markerid="${index}" style="${count > 5 ? 'display: none;' : ''}">
						<img src="${getPinImage(getTypeMap(type), this)}" class="location__pin">
						<div>
							<div><strong>${this.Title}</strong></div>
							<div><span><strong>Address: </strong>${this.Address1}${this.Address2 == '' ? '' : ' ' + this.Address2}, ${this.Town} ${this.PostCode}</span></div>
						</div>
					</li>
				`;
			});

			if (count > 5) {
				$moreContainer.show();
			}

			$('.location__details').empty();
			// $targetList.empty();
			$targetList.append(html);
		}

		// Populate the location list + details for mobile
		function populateMobileList($targetList, type, data) {
			var html = '';
			var count = 0;
			var $moreContainer = $(`.location__navigator[data-type="${type}"] .location__more-container`);

			$moreContainer.hide();
			switch (type) {
				case 'location':
					$.each(data, function(index, value) {
						count++;
						html += `
							<li class="location__list-item" data-markerid="${index}" style="${count > 5 ? 'display: none;' : ''}">
								<div class="down-arrow">
									<img src="${getPinImage(getTypeMap(type), this)}" class="location__pin">
									<div>
										<div><strong>${this.Title}</strong></div>
										<div><span><strong>Address: </strong><a class="address" target="_blank" href="https://www.google.com.au/maps/search/${this.Address1}${this.Address2 == '' ? '' : ' ' + this.Address2}${this.Town} ${this.PostCode}"> ${this.Address1}${this.Address2 == '' ? '' : ' ' + this.Address2}, ${this.Town} ${this.PostCode}</a></span></div>
									</div>
								</div>
								<div class="location__details-col">
									<div class="location__details-table">
										<div class="location__details-row"><span class="location__details-cell"><strong>Phone Number: </strong></span><a href="tel:${this.PhoneNumber}"><span class="location__details-cell">${this.PhoneNumber}</span></a></div><div class="location__details-row"><span class="location__details-cell"><strong>Fax: </strong></span><span class="location__details-cell">${this.Fax}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Opening Hours: </strong></span><span class="location__details-cell">${this.OpeningHours}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Last Truck on site Time: </strong></span><span class="location__details-cell">${this.LastTruckOnSiteTime}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Online Truck Scheduling Available: </strong></span><span class="location__details-cell">${this.OnlineTruckSchedulingAvailable}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Products: </strong></span><span class="location__details-cell"><a href="${this.PdfLink}">Click here</a></span></div>
									</div>
								</div>
								<a class="show-details" href="javascript:void(0)"><span>More</span> details</a>
							</li>
						`;
					});
					break
				case 'supplier':
					$.each(data, function(index, value) {
						count++;
						html += `
							<li class="location__list-item" data-markerid="${index}" style="${count > 5 ? 'display: none;' : ''}">
								<div class="down-arrow">
									<img src="${getPinImage(getTypeMap(type), this)}" class="location__pin">
									<div>
										<div><strong>${this.Title}</strong></div>
										<div><span><strong>Address: </strong><a class="address" target="_blank" href="https://www.google.com.au/maps/search/${this.Address1}${this.Address2 == '' ? '' : ' ' + this.Address2}${this.Town} ${this.PostCode}">${this.Address1}${this.Address2 == '' ? '' : ' ' + this.Address2}, ${this.Town} ${this.PostCode}</a></span></div>
									</div>
								</div>
								<div class="location__details-col">
									<div class="location__details-table">

										<div class="location__details-row"><span class="location__details-cell"><strong>Address: </strong></span><span class="location__details-cell">${this.Address1}</span></div>

										<div class="location__details-row"><span class="location__details-cell"></span><span class="location__details-cell">${this.Address2}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Town: </strong></span><span class="location__details-cell">${this.Town}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>State: </strong></span><span class="location__details-cell">${this.State}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Postcode: </strong></span><span class="location__details-cell">${this.PostCode}</span></div>


										<div class="location__details-row"><span class="location__details-cell"><strong>Title: </strong></span><span class="location__details-cell">${this.Title}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Phone Number: </strong></span><a href="tel:${this.PhoneNumber}"><span class="location__details-cell">${this.PhoneNumber}</span></a></div></div>

									</div>
								</div>
								<a class="show-details" href="javascript:void(0)"><span>More</span> details</a>
							</li>
						`;
					});
					break
				case 'dealersupplier':
					$.each(data, function(index, value) {
						count++;
						html += `
							<li class="location__list-item" data-markerid="${index}" style="${count > 5 ? 'display: none;' : ''}">
								<div class="down-arrow">
									<img src="${getPinImage(getTypeMap(type), this)}" class="location__pin">
									<div>
										<div><strong>${this.Title}</strong></div>
										<div><span><strong>Address: </strong><a class="address" target="_blank" href="https://www.google.com.au/maps/search/${this.Address1}${this.Address2 == '' ? '' : ' ' + this.Address2}${this.Town} ${this.PostCode}">${this.Address1}${this.Address2 == '' ? '' : ' ' + this.Address2}, ${this.Town} ${this.PostCode}</a></span></div>
									</div>
								</div>
								<div class="location__details-col">
									<div class="location__details-table">

										<div class="location__details-row"><span class="location__details-cell"><strong>Address: </strong></span><span class="location__details-cell">${this.Address1}</span></div>

										<div class="location__details-row"><span class="location__details-cell"></span><span class="location__details-cell">${this.Address2}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Town: </strong></span><span class="location__details-cell">${this.Town}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>State: </strong></span><span class="location__details-cell">${this.State}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Postcode: </strong></span><span class="location__details-cell">${this.PostCode}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Title: </strong></span><span class="location__details-cell">${this.Title}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Phone Number: </strong></span><a href="tel:${this.PhoneNumber}"><span class="location__details-cell">${this.PhoneNumber}</span></a></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Contact Name: </strong></span><span class="location__details-cell">${this.ContactName}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Email Address: </strong></span><a href="mailto:${this.EmailAddress}"><span class="location__details-cell">${this.EmailAddress}</span></a></div></div>

									</div>
								</div>
								<a class="show-details" href="javascript:void(0)"><span>More</span> details</a>
							</li>
						`;
					});
					break
				case 'dealer':
					$.each(data, function(index, value) {
						count++;
						html += `
							<li class="location__list-item" data-markerid="${index}" style="${count > 5 ? 'display: none;' : ''}">
								<div class="down-arrow">
									<img src="${getPinImage(getTypeMap(type), this)}" class="location__pin">
									<div>
										<div><strong>${this.Title}</strong></div>
										<div><span><strong>Address: </strong><a class="address" target="_blank" href="https://www.google.com.au/maps/search/${this.Address1}${this.Address2 == '' ? '' : ' ' + this.Address2}${this.Town} ${this.PostCode}">${this.Address1}${this.Address2 == '' ? '' : ' ' + this.Address2}, ${this.Town} ${this.PostCode}</a></span></div>
									</div>
								</div>
								<div class="location__details-col">
									<div class="location__details-table">
										<div class="location__details-row"><span class="location__details-cell"><strong>Address: </strong></span><span class="location__details-cell">${this.Address1}</span></div>

										<div class="location__details-row"><span class="location__details-cell"></span><span class="location__details-cell">${this.Address2}</span></div>
										<div class="location__details-row"><span class="location__details-cell"><strong>Town: </strong></span><span class="location__details-cell">${this.Town}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Postcode: </strong></span><span class="location__details-cell">${this.PostCode}</span></div>

										<div class="location__details-row"><span class="location__details-cell"><strong>Phone Number: </strong></span><a href="tel:${this.PhoneNumber}"><span class="location__details-cell">${this.PhoneNumber}</span></a></div></div></div><div class="location__details-col"></div>
									</div>
								</div>
								<a class="show-details" href="javascript:void(0)"><span>More</span> details</a>
							</li>
						`;
					});
					break
			}
			if (count > 5) {
				$moreContainer.show();
			}

			$('.location__details').empty();
			// $targetList.empty();
			$targetList.append(html);
		}

		// Populate the extra location details for desktop
		function populateDetails(type, data) {
			switch (type) {
				case 'location':
					var html = `
						<h3>${data.Title}</h3>
						<div>
							<div class="location__details-col">
								<div class="location__details-table">

									<div class="location__details-row"><span class="location__details-cell"><strong>Address: </strong></span><span class="location__details-cell">${data.Address1}</span></div><div class="location__details-row"><span class="location__details-cell"></span><span class="location__details-cell">${data.Address2}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Town: </strong></span><span class="location__details-cell">${data.Town}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>State: </strong></span><span class="location__details-cell">${data.State}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Postcode: </strong></span><span class="location__details-cell">${data.PostCode}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Last Truck on site Time: </strong></span><span class="location__details-cell">${data.LastTruckOnSiteTime}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Online Truck Scheduling Available: </strong></span><span class="location__details-cell">${data.OnlineTruckSchedulingAvailable}</span></div>
								</div>
							</div>
							<div class="location__details-col">
								<div class="location__details-table">
									<div class="location__details-row"><span class="location__details-cell"><strong>Location Type: </strong></span><span class="location__details-cell">${data.LocationType}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Phone Number: </strong></span><span class="location__details-cell">${data.PhoneNumber}</span></div><div class="location__details-row"><span class="location__details-cell"><strong>Fax: </strong></span><span class="location__details-cell">${data.Fax}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Products: </strong></span><span class="location__details-cell"><a href="${data.PdfLink}">Click here</a></span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Opening Hours: </strong></span><span class="location__details-cell">${data.OpeningHours}</span></div>
								</div>
							</div>
						</div>

					`;
					$('.location__navigator[data-type="location"] .location__details').html(html)
					if (!$isMobile) {
						var $newHeight = $('.location__navigator[data-type="location"] .location__navigator-col:nth-child(2) div').height();
						$('.location__navigator[data-type="location"] .location__navigator-col:first-child()').height($newHeight);
					}
					break
				case 'supplier':
					var html = `
						<h3>${data.SupplierName}</h3>
						<div>
							<div class="location__details-col">
								<div class="location__details-table">

									<div class="location__details-row"><span class="location__details-cell"><strong>Address: </strong></span><span class="location__details-cell">${data.Address1}</span></div>

									<div class="location__details-row"><span class="location__details-cell"></span><span class="location__details-cell">${data.Address2}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Town: </strong></span><span class="location__details-cell">${data.Town}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>State: </strong></span><span class="location__details-cell">${data.State}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Postcode: </strong></span><span class="location__details-cell">${data.PostCode}</span></div>

								</div>
							</div>
							<div class="location__details-col">
								<div class="location__details-table">
									<div class="location__details-row"><span class="location__details-cell"><strong>Title: </strong></span><span class="location__details-cell">${data.Title}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Phone Number: </strong></span><span class="location__details-cell">${data.PhoneNumber}</span></div></div>

								</div>
							</div>
						</div>
					`;
					$('.location__navigator[data-type="supplier"] .location__details').html(html)
					if (!$isMobile) {
						var $newHeight = $('.location__navigator[data-type="supplier"] .location__navigator-col:nth-child(2) div').height();
						$('.location__navigator[data-type="supplier"] .location__navigator-col:first-child()').height($newHeight);
					}
					break
				case 'dealer':
					var html = `
						<h3>${data.Title}</h3>
						<div>
							<div class="location__details-col">
								<div class="location__details-table">
									<div class="location__details-row"><span class="location__details-cell"><strong>Address: </strong></span><span class="location__details-cell">${data.Address1}</span></div>

									<div class="location__details-row"><span class="location__details-cell"></span><span class="location__details-cell">${data.Address2}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Town: </strong></span><span class="location__details-cell">${data.Town}</span></div>
								</div>
							</div>
							<div class="location__details-col">
								<div class="location__details-table">
									<div class="location__details-row"><span class="location__details-cell"><strong>State: </strong></span><span class="location__details-cell">${data.State}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Postcode: </strong></span><span class="location__details-cell">${data.PostCode}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Phone Number: </strong></span><span class="location__details-cell">${data.PhoneNumber}</span></div></div>
								</div>
							</div>
						</div>

					`;
					$('.location__navigator[data-type="dealer"] .location__details').html(html)
					if (!$isMobile) {
						var $newHeight = $('.location__navigator[data-type="dealer"] .location__navigator-col:nth-child(2) div').height();
						$('.location__navigator[data-type="dealer"] .location__navigator-col:first-child()').height($newHeight);
					}
					break
				case 'dealersupplier':
					var html = `
						<h3>${data.Title}</h3>
						<div>
							<div class="location__details-col">
								<div class="location__details-table">
									<div class="location__details-row"><span class="location__details-cell"><strong>Address: </strong></span><span class="location__details-cell">${data.Address1}</span></div>

									<div class="location__details-row"><span class="location__details-cell"></span><span class="location__details-cell">${data.Address2}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Town: </strong></span><span class="location__details-cell">${data.Town}</span></div>
									${(() => {return data.SupplierType === 0 ? '<div class="location__details-row"><span class="location__details-cell"><strong>Dealer: </strong></span><span class="location__details-cell">' + data.SupplierName + '</span></div>' : ''})()}
								</div>
							</div>
							<div class="location__details-col">
								<div class="location__details-table">
									<div class="location__details-row"><span class="location__details-cell"><strong>State: </strong></span><span class="location__details-cell">${data.State}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Postcode: </strong></span><span class="location__details-cell">${data.PostCode}</span></div>

									<div class="location__details-row"><span class="location__details-cell"><strong>Phone Number: </strong></span><span class="location__details-cell">${data.PhoneNumber}</span></div>
									<div class="location__details-row"><span class="location__details-cell"><strong>Contact Name: </strong></span><span class="location__details-cell">${data.ContactName}</span></div>
									<div class="location__details-row"><span class="location__details-cell"><strong>Email Address: </strong></span><a href="mailto:${data.EmailAddress}"><span class="location__details-cell">${data.EmailAddress}</span></a></div>
								</div>
							</div>
						</div>

					`;
					$('.location__navigator[data-type="dealersupplier"] .location__details').html(html)
					if (!$isMobile) {
						var $newHeight = $('.location__navigator[data-type="dealersupplier"] .location__navigator-col:nth-child(2) div').height();
						$('.location__navigator[data-type="dealersupplier"] .location__navigator-col:first-child()').height($newHeight);
					}
					break
			}

		}

		function getTypeMap(type) {
			var map = {
				'supplier': 'default',
				'dealer': 'dealer',
				'dealersupplier': 'dealersupplier',
				'location': 'LocationType',
				'customerservice': 'customerservice',
				'headoffice': 'headoffice'
			}

			return map[type] != null ? map[type] : 'default';
		}

		function getPinImage(pinType, data) {

			var map = {
				'pdc': '../resources/ipf/imgs/pin-primarydist.png',
				'distribution centre': '../resources/ipf/imgs/pin-primarydist.png',
				'manufacturing site': '../resources/ipf/imgs/pin-independents.png',
				'head office': '../resources/ipf/imgs/pin-aglink.png',
				'customer service': '../resources/ipf/imgs/pin-customerservice.png',
				'big n depot': '../resources/ipf/imgs/pin-bigndepot.png',


			};

			if (data[pinType] != null && map[data[pinType].toLowerCase()] != null) {
				return map[data[pinType].toLowerCase()]
			} else if(pinType == 'dealer') {
				return '../resources/ipf/imgs/pin-dealers.png';
			} else if(pinType == 'dealersupplier') {
				return data.SupplierType ? '../resources/ipf/imgs/pin-dealers.png' : '../resources/ipf/imgs/pin.png';
			} else {
				return '../resources/ipf/imgs/pin.png';
			}
		}

		function getMapObjectByType(type) {
			switch (type) {
				case 'supplier':
					return mapSupplier;
					break
				case 'dealer':
					return mapDealer;
					break
				case 'dealersupplier':
					return mapDealersupplier;
					break
				case 'location':
					return mapLocation;
					break
			}
		}

		function getMarkersByType(type) {
			switch (type) {
				case 'supplier':
					return markersSupplier;
					break
				case 'dealer':
					return markersDealer;
					break
				case 'dealersupplier':
					return markersDealersupplier;
					break
				case 'location':
					return markersLocation;
					break
			}
		}

		function initMap(type, filter = false, dataStart, dataCount) {
			clearMarkers();
			clearClusterMarkers();

			switch (type) {
				case 'supplier':
					// var url = '/api/supplier.json'
					if (filter) {
						var url = `${getEnvironment()}/webapi/LocationApi/FindSupplierLocationsFromCsv?state=${$('#supplier-state option:selected').val()}&town=${$('#supplier-town').val()}&postCode=${$('#supplier-postcode').val()}`
					} else {
						var url = `${getEnvironment()}/webapi/LocationApi/FindSupplierLocationsFromCsv?state=&town=&postCode=`
					}
					break
				case 'dealer':
					// var url = '/api/dealer.json'
					if (filter) {
						var url = `${getEnvironment()}/webapi/LocationApi/FindBigNLocationsFromCsv?state=${$('#dealer-state option:selected').val()}&title=${$('#dealer-partner').val()}`
					} else {
						var url = `${getEnvironment()}/webapi/LocationApi/FindBigNLocationsFromCsv?state=&partnerType=`
					}
					break
				case 'dealersupplier':
					// var url = '/api/dealersupplier.json'
					if (filter) {
						var url = `${getEnvironment()}/webapi/LocationApi/FindCombinedLocationsFromCsv?bigNAgentName=${$('#dealersupplier-bignagent').val()}&state=${$('#dealersupplier-state option:selected').val()}&town=${$('#dealersupplier-town').val()}&postCode=${$('#dealersupplier-postcode').val()}`
					} else {
						var url = `${getEnvironment()}/webapi/LocationApi/FindCombinedLocationsFromCsv?bigNAgentName=&state=&town=&postCode=`
					}
					break
				case 'location':
					// var url = '/api/location.json'
					if (filter) {
						var url = `${getEnvironment()}/webapi/LocationApi/FindIPFLocationsFromCsv?locationtype=${$('#location-locationtype option:selected').val()}&town=${$('#location-town').val()}&postCode=${$('#location-postcode').val()}`
					} else {
						var url = `${getEnvironment()}/webapi/LocationApi/FindIPFLocationsFromCsv?locationtype=&town=&postCode=`
					}
					break
			}


			dataStart = 0;
			$.ajax({
				// url: '/api/full.json',
				url: url,
				data: {Start: dataStart, Count: dataCount}
			}).done(function(data) {
				if(!data.Results) {
        	// $(".location__navigator-container").prepend("<div class='message'>No results.</div>");
        	// $('li.loader').empty();
					endLoadMore = false;
					var map = getMapObjectByType(type);
					$.each(data, function(index, value) {
						var marker =  new google.maps.Marker({
							position: {lat: parseFloat(this.Latitude), lng: parseFloat(this.Longitude)},
							icon: getPinImage(getTypeMap(type), this)
						});
						getMarkersByType(type).push(marker);

						marker.addListener('click', function(){
							map.setCenter(marker.getPosition());
						});
					});

					if(!data.length) {
						$(".location__navigator-container").prepend("<div class='message'>No results.</div>");
					}

					else if(data.length > 0) {
						// Result found for the search effected - dont do anything, carry on normal process
						if(data.PostcodeStatus === 'Nearest') {
								// Display message saying showing closest postcode
								$( ".location__navigator-container" ).prepend( "<div class='message'>No results found. Here are the nearest locations</div>");
							}
					}

					else if($('#dealersupplier-town').val().length > 0 && $('#dealersupplier-postcode').val().length === 0) {
						// Do alert of display message please search by postcode
							$( ".location__navigator-container" ).prepend( "<div class='message'>No results found. Please refine your search by postcode</div>");
					}

					else if(!parseInt($('#dealersupplier-postcode').val()) > 0 || !$('#dealersupplier-postcode').val().length === 4 ) {
						// If postcode is not 4 numbers then say invalid postcode
							$( ".location__navigator-container" ).prepend( "<div class='message'>No results found.</div>");
					}

					if ($isMobile) {
						listReset($(`.location__navigator[data-type="${type}"] .location__list`));
						populateMobileList($(`.location__navigator[data-type="${type}"] .location__list`), type, data);

						// $(`.location__navigator[data-type="${type}"] .location__list`).on('click', '.show-details', function() {
						// 	if (!$(this).parent().hasClass('active')) {
						// 		$('.location__list-item').removeClass('active').find('.show-details span').html('More');
						// 		$(this).parent().addClass('active').find('.show-details span').html('Less');
						// 	} else {
						// 		$('.location__list-item').removeClass('active').find('.show-details span').html('More');
						// 	}
						// });
					} else {
	          // Create a bound object
	          var bounds = new google.maps.LatLngBounds();
						$.each(getMarkersByType(type), function(index, value) {
	                    	// Add the marker cordinate to the bound
	                    	// limit to first 5 locations
	                    	if(index<5){
	                    		bounds.extend(new google.maps.LatLng(value.getPosition().lat(), value.getPosition().lng()));
	                    	}


							getMarkersByType(type)[index].addListener('click', function() {
								map.setZoom(16);
								//map.setCenter(getMarkersByType(type)[index].getPosition());
								$('.location__list-item').removeClass('active');
								$('[data-markerid="' + index + '"]').addClass('active');
								populateDetails(type, data[index]);
							});
						});

						// Zoom map to bounds
	          map.fitBounds(bounds);

	          listReset($(`.location__navigator[data-type="${type}"] .location__list`));

						populateList($(`.location__navigator[data-type="${type}"] .location__list`), type, data);

						$(`.location__navigator[data-type="${type}"] .location__list`).off();

						$(`.location__navigator[data-type="${type}"] .location__list`).on('click', '.location__list-item', function() {
							if (!$(this).hasClass('active')) {
								$('.location__list-item').removeClass('active');
								$(this).addClass('active');
								google.maps.event.trigger(getMarkersByType(type)[$(this).data('markerid')], 'click');
								populateDetails(type, data[$(this).data('markerid')]);
							}
						});



						clearClusterMarkers();

						// Add a marker clusterer to manage the getMarkersByType(type).
						if (type == 'supplier') {
							markerClustersSupplier = new MarkerClusterer(map, getMarkersByType(type), {
								imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
								averageCenter: true
							});
						}
						else if (type == 'dealer') {
							markerClustersDealer = new MarkerClusterer(map, getMarkersByType(type), {
								imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
								averageCenter: true
							});

						}
						else if (type == 'dealersupplier') {
							markerClustersDealersupplier = new MarkerClusterer(map, getMarkersByType(type), {
								imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
								averageCenter: true,
							});
						}
						else if (type == 'location') {
							markerClustersLocation = new MarkerClusterer(map, getMarkersByType(type), {
								imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
								averageCenter: true
							});
						}
					}



        } else {
					//if there are data.Results
					// check return is empty array or not

					var map = getMapObjectByType(type);

					//load address data and store them in array//Initial call
					$.each(data.Results, function(index, value) {
						var marker =  new google.maps.Marker({
							position: {lat: parseFloat(this.Latitude), lng: parseFloat(this.Longitude)},
							icon: getPinImage(getTypeMap(type), this)
						});
						getMarkersByType(type).push(marker);

						marker.addListener('click', function(){
							map.setCenter(marker.getPosition());
						});
					});



	        // Check if there is results found
	        // if(!data.length){
	        if(!data.Results.length) {
	        	$(".location__navigator-container").prepend("<div class='message'>No results.</div>");
	        }

	        else if(data.Results.length > 0) {
	        	// Result found for the search effected - dont do anything, carry on normal process
	        	if(data.PostcodeStatus === 'Nearest') {
	            	// Display message saying showing closest postcode
	            	$( ".location__navigator-container" ).prepend( "<div class='message'>No results found. Here are the nearest locations</div>");
	            }
	        }

	        else if($('#dealersupplier-town').val().length > 0 && $('#dealersupplier-postcode').val().length === 0) {
	        	// Do alert of display message please search by postcode
	            $( ".location__navigator-container" ).prepend( "<div class='message'>No results found. Please refine your search by postcode</div>");
	        }

	        else if(!parseInt($('#dealersupplier-postcode').val()) > 0 || !$('#dealersupplier-postcode').val().length === 4 ) {
	        	// If postcode is not 4 numbers then say invalid postcode
	            $( ".location__navigator-container" ).prepend( "<div class='message'>No results found.</div>");
	        }


					if ($isMobile) {
						listReset($(`.location__navigator[data-type="${type}"] .location__list`));
						populateMobileList($(`.location__navigator[data-type="${type}"] .location__list`), type, data.Results);

						// $(`.location__navigator[data-type="${type}"] .location__list`).on('click', '.show-details', function() {
						// 	if (!$(this).parent().hasClass('active')) {
						// 		$('.location__list-item').removeClass('active').find('.show-details span').html('More');
						// 		$(this).parent().addClass('active').find('.show-details span').html('Less');
						// 	} else {
						// 		$('.location__list-item').removeClass('active').find('.show-details span').html('More');
						// 	}
						// });


					} else {
	          // Create a bound object
	          var bounds = new google.maps.LatLngBounds();
						$.each(getMarkersByType(type), function(index, value) {
	            // limit to first 5 locations
							// Add the marker cordinate to the bound
	            if(index<5){
	            	bounds.extend(new google.maps.LatLng(value.getPosition().lat(), value.getPosition().lng()));
	            }


							getMarkersByType(type)[index].addListener('click', function() {
								map.setZoom(16);
								//map.setCenter(getMarkersByType(type)[index].getPosition());
								$('.location__list-item').removeClass('active');
								$('[data-markerid="' + index + '"]').addClass('active');
								populateDetails(type, data.Results[index]);
							});
						});


	          // Zoom map to bounds
	          map.fitBounds(bounds);

	          listReset($(`.location__navigator[data-type="${type}"] .location__list`));

						populateList($(`.location__navigator[data-type="${type}"] .location__list`), type, data.Results);

						$(`.location__navigator[data-type="${type}"] .location__list`).off();

						$(`.location__navigator[data-type="${type}"] .location__list`).on('click', '.location__list-item', function() {
							if (!$(this).hasClass('active')) {
								$('.location__list-item').removeClass('active');
								$(this).addClass('active');
								google.maps.event.trigger(getMarkersByType(type)[$(this).data('markerid')], 'click');
								populateDetails(type, data.Results[$(this).data('markerid')]);

								var setLatlng = {
									lat: data.Results[$(this).data('markerid')].Latitude,
									lng: data.Results[$(this).data('markerid')].Longitude
								};
							}
						});


						clearClusterMarkers();
						// Add a marker clusterer to manage the getMarkersByType(type).
						if (type == 'supplier') {
							markerClustersSupplier = new MarkerClusterer(map, getMarkersByType(type), {
								imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
								averageCenter: true
							});
						}
						else if (type == 'dealer') {
							markerClustersDealer = new MarkerClusterer(map, getMarkersByType(type), {
								imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
								averageCenter: true
							});
						}
						else if (type == 'dealersupplier') {
							markerClustersDealersupplier = new MarkerClusterer(map, getMarkersByType(type), {
								imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
								averageCenter: true
							});
						}
						else if (type == 'location') {
							markerClustersLocation = new MarkerClusterer(map, getMarkersByType(type), {
								imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
								averageCenter: true
							});
						}
					}
				}

			}).fail(function(error) {
				console.log('Error: ' + error);
			})
		}

		function clearClusterMarkers() {
			if (markerClustersSupplier != null) {
				markerClustersSupplier.clearMarkers();
			}
			if (markerClustersDealer != null) {
				markerClustersDealer.clearMarkers();
			}
			if (markerClustersDealersupplier != null) {
				markerClustersDealersupplier.clearMarkers();
			}
			if (markerClustersLocation != null) {
				markerClustersLocation.clearMarkers();
			}
		}

		function clearMarkers() {
			for (var i = 0; i < markersSupplier.length; i++) {
				markersSupplier[i].setMap(null);
			}
			for (var i = 0; i < markersDealer.length; i++) {
				markersDealer[i].setMap(null);
			}
			for (var i = 0; i < markersDealersupplier.length; i++) {
				markersDealersupplier[i].setMap(null);
			}
			for (var i = 0; i < markersLocation.length; i++) {
				markersLocation[i].setMap(null);
			}
			markersSupplier = [];
			markersDealer = [];
			markersDealersupplier = [];
			markersLocation = [];
		}

		function loadMore(type, $button, filter = false) {
			var map = getMapObjectByType(type);
			var items = $(`.location__navigator[data-type="${type}"] .location__list-item`).not(':visible');
			var totalItems = $(`.location__navigator[data-type="${type}"] .location__list-item`);


			if (items.length > 0) {
				// if (items.length <= 5 && endLoadMore == true) {
				if (items.length <= 4) {
					$button.parent('.location__more-container').hide();
				}
				for (var i = 0; i < 5; i++) {
					$(items[i]).show();
				}
			}

			if (!$isMobile) {
				var   $target = $('.location__navigator[data-type="'+ type +'"]'),
				$newHeight = $target.find('.location__navigator-col:nth-child(2) div').height();

				$target.find('.location__navigator-col:first-child()').height($newHeight);
				// $target.find('.location__navigator-col:first-child()').scrollTop($newHeight);
			}

			// check call list or not
			if(items.length == 0){
				callList = true;

				if(callList){
					switch (type) {
					case 'supplier':
						// var url = '/api/supplier.json'
						if (filter) {
							var url = `${getEnvironment()}/webapi/LocationApi/FindSupplierLocationsFromCsv?state=${$('#supplier-state option:selected').val()}&town=${$('#supplier-town').val()}&postCode=${$('#supplier-postcode').val()}`
						} else {
							var url = `${getEnvironment()}/webapi/LocationApi/FindSupplierLocationsFromCsv?state=&town=&postCode=`
						}
						break
					case 'dealer':
						// var url = '/api/dealer.json'
						if (filter) {
							var url = `${getEnvironment()}/webapi/LocationApi/FindBigNLocationsFromCsv?state=${$('#dealer-state option:selected').val()}&title=${$('#dealer-partner').val()}`
						} else {
							var url = `${getEnvironment()}/webapi/LocationApi/FindBigNLocationsFromCsv?state=&partnerType=`
						}

						break
					case 'dealersupplier':
						// var url = '/api/dealersupplier.json'
						if (filter) {
							var url = `${getEnvironment()}/webapi/LocationApi/FindCombinedLocationsFromCsv?bigNAgentName=${$('#dealersupplier-bignagent').val()}&state=${$('#dealersupplier-state option:selected').val()}&town=${$('#dealersupplier-town').val()}&postCode=${$('#dealersupplier-postcode').val()}`
						} else {
							var url = `${getEnvironment()}/webapi/LocationApi/FindCombinedLocationsFromCsv?bigNAgentName=&state=&town=&postCode=`
						}
						break
					case 'location':
						// var url = '/api/location.json'
						if (filter) {
							var url = `${getEnvironment()}/webapi/LocationApi/FindIPFLocationsFromCsv?locationtype=${$('#location-locationtype option:selected').val()}&town=${$('#location-town').val()}&postCode=${$('#location-postcode').val()}`
						} else {
							var url = `${getEnvironment()}/webapi/LocationApi/FindIPFLocationsFromCsv?locationtype=&town=&postCode=`
						}
						break
					}

					$.ajax({
						// url: '/api/full.json',
						url: url,
						data: { Start: nextStart, Count: dataCount }
					}).done(function(data) {
						$('#dealersupplier-reset').on('click', function(){
							nextStart = 14;
						});
						$('#dealersupplier-submit').on('click', function(){
							nextStart = 14;
						});
						$('#location-reset').on('click', function(){
							nextStart = 14;
						});
						$('#location-submit').on('click', function(){
							nextStart = 14;
						});

						nextStart = nextStart + dataCount;
						if(totalItems.length == data.Total){
							endLoadMore = true;
						}

						var map = getMapObjectByType(type);

					//load address data and add them in array
						$.each(data.Results, function(index, value) {
							var marker =  new google.maps.Marker({
								position: {lat: parseFloat(this.Latitude), lng: parseFloat(this.Longitude)},
								icon: getPinImage(getTypeMap(type), this)
							});
							getMarkersByType(type).push(marker);

							marker.addListener('click', function(){
								map.setCenter(marker.getPosition());
							});
						});
						//////////////////
						if ($isMobile) {
							populateMobileList($(`.location__navigator[data-type="${type}"] .location__list`), type, data.Results);

							$(`.location__navigator[data-type="${type}"] .location__list`).on('click', '.show-details', function() {
								if (!$(this).parent().hasClass('active')) {
									$('.location__list-item').removeClass('active').find('.show-details span').html('More');
									$(this).parent().addClass('active').find('.show-details span').html('Less');
								} else {
									$('.location__list-item').removeClass('active').find('.show-details span').html('More');
								}
							});
						} else {
							 // Zoom map to bounds
							 map.fitBounds(bounds);

							populateList($(`.location__navigator[data-type="${type}"] .location__list`), type, data.Results);

							$(`.location__navigator[data-type="${type}"] .location__list`).off();

							$(`.location__navigator[data-type="${type}"] .location__list`).on('click', '.location__list-item', function() {
								if (!$(this).hasClass('active')) {
									$('.location__list-item').removeClass('active');
									$(this).addClass('active');
									google.maps.event.trigger(getMarkersByType(type)[$(this).data('markerid')], 'click');
									populateDetails(type, data.Results[$(this).data('markerid')]);
								}
							});
							clearClusterMarkers();

							// Add a marker clusterer to manage the getMarkersByType(type).
							if (type == 'supplier') {
								markerClustersSupplier = new MarkerClusterer(map, getMarkersByType(type), {
									imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
									averageCenter: true
								});
							}
							else if (type == 'dealer') {
								markerClustersDealer = new MarkerClusterer(map, getMarkersByType(type), {
									imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
									averageCenter: true
								});

							}
							else if (type == 'dealersupplier') {
								markerClustersDealersupplier = new MarkerClusterer(map, getMarkersByType(type), {
									imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
									averageCenter: true
								});
							}
							else if (type == 'location') {
								markerClustersLocation = new MarkerClusterer(map, getMarkersByType(type), {
									imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
									averageCenter: true
								});
							}
						}
						//////////////////
					});
					callList = false;
				}

			}

			// display additional markers by new bounds
			var bounds = new google.maps.LatLngBounds();
			$.each(getMarkersByType(type), function(index, value) {
                // Add the marker cordinate to the bound
                // if(index<dataIndex){
                	bounds.extend(new google.maps.LatLng(value.getPosition().lat(), value.getPosition().lng()));
                // }
			});

			map.fitBounds(bounds);
		}
	}
}

//text description chnages based on option selected from drop down
$('.location__select').change(function(){
    var $value = $('.location__select :selected').val();
    $('div.textmsg').hide();
    $('div.message').remove();
		$('.location__list-item').remove();

    if($value == 'location'){
      $('div.location-text').show();
    } else if($value == 'dealersupplier'){
      $('div.dealer-supplier-text').show();
    } else {
      $('div.default-text').show();
    }
});

$('select.location__select option').each(function(){
	if(this.defaultSelected){
		this.selected = true;
		return false;
	}
});

//Mobile view, "More details" and "Less details" function
$(`.location__navigator .location__list`).on('click', '.show-details', function() {
	if (!$(this).parent().hasClass('active')) {
		$('.location__list-item').removeClass('active').find('.show-details span').html('More');
		$(this).parent().addClass('active').find('.show-details span').html('Less');
	} else {
		$('.location__list-item').removeClass('active').find('.show-details span').html('More');
	}
});
